<template>
  <!-- 评价工种维护 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <!-- 头部面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">基础信息</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">评价工种维护</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{occupationId?'编辑':'新增'}}</a>
        </span>
      </div>
      <!-- 主体区域 -->
      <div class="framePage-body">
        <el-container>
          <el-main>
            <el-form ref="formData" :rules="rules" :model="formData" size="small" label-width="100px">
              <el-form-item label="工种名称" prop="occupationName">
                <el-input v-model="formData.occupationName" placeholder="请输入工种名称"></el-input>
              </el-form-item>
              <el-form-item label="培训等级" prop="trainLevel">
                <el-select clearable placeholder="请选择培训等级" v-model="formData.trainLevel">
                  <el-option v-for="item in trainingLevelList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="培训类型" prop="categoryId">
                <el-select clearable placeholder="请选择培训类型" v-model="formData.categoryId">
                  <el-option v-for="item in trainingTypeList" :key="item.categoryId" :label="item.categoryName" :value="item.categoryId">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- 必传材料 -->
              <el-form-item label="必传材料" prop="selectiveAttributeList">
                <div class="df">
                  <el-table ref="selectiveAttributeList1" class="sortable1" :data="formData.selectiveAttributeList1" row-key="attributeKey" size="mini" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
                    <el-table-column label="拖拽" align="center" width="50">
                      <template slot-scope="scope">
                        <i class="el-icon-rank" title="拖拽调整排序"></i>
                      </template>
                    </el-table-column>
                    <el-table-column label="序号" align="center" type="index" />
                    <el-table-column label="材料名称" align="center" show-overflow-tooltip prop="attributeLabel" />
                    <el-table-column label="是否必传" align="center" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.requiredField"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column label="描述" align="center" show-overflow-tooltip prop="attributeDescribe" />
                    <el-table-column label="文件大小限制" align="center">
                      <template slot-scope="{row}" >
                          {{(typeof(row.attributeFileSizeMin) == "number"&&typeof(row.attributeFileSizeMax) == "number")?`${row.attributeFileSizeMin} - ${row.attributeFileSizeMax}k`:'无限制'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="120px">
                      <template slot-scope="scope">
                        <el-button type="text" style="padding: 0px 5px" @click="()=>formData.selectiveAttributeList1.splice(scope.$index,1)" size="mini">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div style="width:250px;margin-left: 20px;">
                    <p>请选择需要上传的材料</p>
                    <div class="el-form-item-val">
                      <el-checkbox-group v-model="formData.selectiveAttributeList1">
                        <el-checkbox v-for="item in availableProps1" :key="item.attributeKey" :label="item">{{item.attributeLabel}}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <!-- 显示信息项 -->
              <el-form-item label="显示信息项" prop="selectiveAttributeList">
                <div class="df">
                  <el-table ref="selectiveAttributeList2" class="sortable2" :data="formData.selectiveAttributeList2" row-key="attributeKey" size="mini" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
                    <el-table-column label="拖拽" align="center" width="50">
                      <template slot-scope="scope">
                        <i class="el-icon-rank" title="拖拽调整排序"></i>
                      </template>
                    </el-table-column>
                    <el-table-column label="序号" align="center" type="index" />
                    <el-table-column label="信息项名称" align="center" show-overflow-tooltip prop="attributeLabel" />
                    <el-table-column label="是否必填" align="center" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.requiredField"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column label="描述" align="center" show-overflow-tooltip prop="attributeDescribe" />
                    <el-table-column label="操作" align="center" width="120px">
                      <template slot-scope="scope">
                        <el-button type="text" style="padding: 0px 5px" @click="()=>formData.selectiveAttributeList2.splice(scope.$index,1)" size="mini">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div style="width:250px;margin-left: 20px;">
                    <p>请选择输入的项目</p>
                    <div class="el-form-item-val">
                      <el-checkbox-group v-model="formData.selectiveAttributeList2">
                        <el-checkbox v-for="item in availableProps2" :key="item.attributeKey" :label="item">{{item.attributeLabel}}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <!-- 申报条件 -->
              <el-form-item label="申报条件" v-if="isDeclare">
                <el-button type="primary" @click="markInfoCheck" style="margin-bottom: 20px;">选择申报条件</el-button>
                <el-table ref="selectiveConditionList" :data="formData.selectiveConditionList" height="300" row-key="conditionId" size="mini" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
                  <el-table-column label="序号" align="center" type="index" />
                  <el-table-column label="申报条件编码值" align="center" show-overflow-tooltip prop="conditionCode" />
                  <el-table-column label="申报职业编码含义" align="center" show-overflow-tooltip prop="description" />
                  <el-table-column label="操作" align="center" width="120px">
                    <template slot-scope="scope">
                      <el-button type="text" style="padding: 0px 5px" @click="deleteSelectiveCondition(scope.row)" size="mini">删除</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
            </el-form>
          </el-main>
          <el-footer style="text-align:center">
            <el-button class="customBtn" round @click="$router.back()">取 消</el-button>
            <el-button class="customBtn" round type="primary" @click="submit">保 存</el-button>
          </el-footer>
        </el-container>
      </div>
    </div>
    <!-- 选择申报条件 - 弹框 -->
    <el-dialog v-dialogDrag :title="dialogData.dialogTitle" :visible.sync="dialogData.dialogState" width="800px" @close="closeModel">
      <div class="operationControl">
        <div class="searchbox">
          <div title="职业名称" class="searchboxItem ci-full">
            <span class="itemLabel" style="min-width:5rem">职业名称:</span>
            <el-input v-model="dialogData.occupationName" clearable size="small" placeholder="请输入职业名称"></el-input>
          </div>
          <div class="df">
            <el-button type="primary" class="bgc-bv" round @click="dialogGetData()">搜索</el-button>
          </div>
        </div>
      </div>
      <el-table ref="multipleTable" :key="tableKey" :data="dialogData.tableData" row-key="conditionId" @select="handleSelectionChange"
        @select-all="handleAllChange" height="500" size="small" tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader">
        <el-table-column type="selection" align="center" reserve-selection />
        <el-table-column label="职业名称" align="center" show-overflow-tooltip prop="occupationName" />
        <el-table-column label="等级" align="center" show-overflow-tooltip prop="levelName" />
        <el-table-column label="申报职业编码含义" align="center" show-overflow-tooltip prop="description" />
        <el-table-column label="申报条件编码值" align="center" show-overflow-tooltip prop="conditionCode" />
        <Empty slot="empty" />
      </el-table>
      <PageNum :apiData="dialogData.apiData" @sizeChange="dialogSizeChange" @getData="dialogGetData" />
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import Sortable from 'sortablejs'//拖拽排序
export default {
  name: "evaluationMaintenance",
  components: {
    Empty,
    PageNum
  },
  data () {
    const validAttr = (rule, value, callback) => {
      if (this.selectiveAttributeList.length < 1) return callback("请至少选择一项");
      callback();
    }
    return {
      occupationId: "",//工种id
      formData: {
        occupationName: "",//工种名称
        trainLevel: "",//培训等级
        categoryId: "",//培训类型
        selectiveAttributeList1: [],//可选属性1
        selectiveAttributeList2: [],//可选属性2
        selectiveConditionList: [],//选定的申报条件id集合
      },
      echoList:[],
      // 培训等级数据
      trainingLevelList: [],
      // 培训类型数据
      trainingTypeList: [],
      // 可用属性1
      availableProps1: [],
      // 可用属性2
      availableProps2: [],
      // 表单规则
      rules: {
        occupationName: [
          { required: true, message: "请输入工种名称", trigger: "blur" }
        ],
        trainLevel: [
          { required: true, message: "请选择培训等级", trigger: "change" }
        ],
        categoryId: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ],
        selectiveAttributeList: [
          { validator: validAttr, trigger: "change" }
        ],
      },
      tableHeader: {
        background: "#5C6BE8",
        color: "#fff",
        borderColor: "#5C6BE8",
      },
      dialogData: {
        dialogTitle: '选择申报条件',
        dialogState: false,
        occupationName: '',
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        apiData: {
          total: 0,
          size: 10,
          current: 1,
        },
      },
      tableKey:Date.now()
    }
  },
  created () {
    this.initData();
  },
  mounted () {
    // 阻止默认行为
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.rowDrop()//行拖拽
  },
  computed: {
    selectiveAttributeList () {
      return [...this.formData.selectiveAttributeList1, ...this.formData.selectiveAttributeList2].map((e, i) => { return { ...e, orderId: i + 1 } })
    },
    // 申报条件
    isDeclare () {
      return this.selectiveAttributeList.findIndex(e => e.attributeType == '8') !== -1
    }


  },
  methods: {
    // 初始化
    initData () {
      if (this.$route.query.occupationId) {
        this.occupationId = this.$route.query.occupationId
      }
      // 获取培训类型数据
      this.getTrainingTypeData();
      // 获取可用属性
      this.getAvailableProps();
      // 获取培训等级字典数据
      this.getDictionaries();

    },
    //行拖拽
    rowDrop () {
      const tbody1 = document.querySelector('.sortable1 .el-table__body-wrapper tbody')
      const tbody2 = document.querySelector('.sortable2 .el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody1, {
        animation: 150,
        handle: '.el-icon-rank',
        ghostClass: 'blue-background-class',
        onEnd ({ newIndex, oldIndex }) {
          if (newIndex == oldIndex) return
          _this.formData.selectiveAttributeList1.splice(
            newIndex,
            0,
            _this.formData.selectiveAttributeList1.splice(oldIndex, 1)[0]
          )
          var newArray = _this.formData.selectiveAttributeList1.slice(0)
          _this.formData.selectiveAttributeList1 = []
          _this.$nextTick(function () {
            _this.formData.selectiveAttributeList1 = newArray
          })
        }
      })
      Sortable.create(tbody2, {
        animation: 150,
        handle: '.el-icon-rank',
        ghostClass: 'blue-background-class',
        onEnd ({ newIndex, oldIndex }) {
          if (newIndex == oldIndex) return
          _this.formData.selectiveAttributeList2.splice(
            newIndex,
            0,
            _this.formData.selectiveAttributeList2.splice(oldIndex, 1)[0]
          )
          var newArray = _this.formData.selectiveAttributeList2.slice(0)
          _this.formData.selectiveAttributeList2 = []
          _this.$nextTick(function () {
            _this.formData.selectiveAttributeList2 = newArray
          })
        }
      })
    },
    // 编辑回显数据
    getInfo () {
      const { formData } = this;
      return this.$post("/biz/ct/occupation/getInfoNew", {
        occupationId: this.occupationId
      }).then(res => {
        formData.occupationName = res.data.occupationName//工种名称
        formData.trainLevel = res.data.trainLevel//培训等级
        formData.categoryId = res.data.categoryId//培训类型
        formData.selectiveConditionList = res.data.selectiveConditionList//申报条件
        this.echoList = res.data.selectiveConditionList.map(e=>e.conditionId)//申报条件
        return res.data.selectiveAttributeList
      });
    },
    // 表单提交
    submit () {
      const { formData, occupationId } = this;
      // 如果是新增，不需要传工种id
      this.$refs.formData.validate(valid => {
        if (valid) {
          let data = {
            ...formData,
            selectiveAttributeList: this.selectiveAttributeList,
          }
          if (this.isDeclare) {
            data["selectiveConditionList"] = this.echoList
          } else {
            delete data.selectiveConditionList;
          }
          if (!occupationId) {
            // 新增
            this.$post("/biz/ct/occupation/insertNew", data).then(() => {
              this.$message.success("新增工种成功");
              setTimeout(()=>{
                this.$router.back()
              },300)
            }).catch(e => {
              console.error("新增工种出错", e);
            });
          } else {
            // 修改
            data['occupationId'] = occupationId
            this.$post("/biz/ct/occupation/modifyNew", data).then(() => {
              this.$message.success("修改工种成功");
              setTimeout(()=>{
                this.$router.back()
              },300)
            }).catch(e => {
              console.error("修改工种出错", e);
            });
          }
        }
      });
    },
    // 获取培训等级字典数据
    getDictionaries () {
      const trainLevleData = this.$setDictionary("ET_TRAIN_LEVEL", "list");
      Object.keys(trainLevleData).forEach(item => {
        this.trainingLevelList.push({
          label: trainLevleData[item],
          value: item
        });
      });
    },
    // 获取培训类型数据
    getTrainingTypeData () {
      this.$post("/biz/ct/category/list").then(res => {
        this.trainingTypeList = res.data;
      }).catch(e => {
        console.error("获取培训类型数据出错", e);
      });
    },
    // 获取可用属性
    async getAvailableProps () {
      const { formData,occupationId } = this;
      let info
      if(occupationId){
        info = await this.getInfo()
      }
      this.$post("/biz/ct/attribute/available").then(res => {
        res.data.forEach(item => {
          if (!occupationId) {
            // 新增处理数据
            item['requiredField'] = true
          } else {
            // 编辑处理数据
            item['requiredField'] = info.find(e => e.attributeId == item.attributeId)?.requiredField ?? true
            item['orderId'] = info.find(e => e.attributeId == item.attributeId)?.orderId
          }
          // 分类可选属性
          if (item.groupKey === "1_UPLOAD") {
            this.availableProps1.push(item);
            // 赋值默认选中的属性
            if (!occupationId) {
              if(item.defaultUse){
                formData.selectiveAttributeList1.push(item);
              }
            }else{
              if(info.findIndex(e => e.attributeId == item.attributeId)!=-1){
                formData.selectiveAttributeList1.push(item);
              }
            }
          } else {
            this.availableProps2.push(item);
            // 赋值默认选中的属性
            if (!occupationId) {
              if(item.defaultUse){
                formData.selectiveAttributeList2.push(item);
              }
            }else{
              if(info.findIndex(e => e.attributeId == item.attributeId)!=-1){
                formData.selectiveAttributeList2.push(item);
              }
            }
          }
        });
         formData.selectiveAttributeList1.sort((a,b) => a.orderId - b.orderId)
         formData.selectiveAttributeList2.sort((a,b) => a.orderId - b.orderId)
      }).catch(e => {
        console.error("获取可用属性出错", e);
      });
    },
    // 弹窗start
    closeModel () {
      this.dialogData = this.$options.data().dialogData
    },
    //这里是做单项选择与单项反选的 选择：打勾或取消
    handleSelectionChange(selected, row) {
      if (!this.echoList.includes(row.conditionId)) {
        // 回显数据里没有本条，把这条加进来(选中)
        this.echoList.push(row.conditionId);
        this.formData.selectiveConditionList.push(row)
      } else {
        // 回显数据里有本条，把这条删除(取消选中)
        this.echoList.forEach((conditionId, index) => {
          if (conditionId === row.conditionId) {
            this.echoList.splice(index, 1);
            this.formData.selectiveConditionList.splice(index, 1);
          }
        });
      }
    },
    // 全选、取消全选（原理同上面的单选）
    handleAllChange(selected) {
      if (selected.length > 0) {
        selected.forEach((item) => {
          if (!this.echoList.includes(item.conditionId)) {
            this.echoList.push(item.conditionId);
            this.formData.selectiveConditionList.push(item)
          }
        });
      } else {
        this.dialogData.tableData.forEach((item) => {
          this.echoList.forEach((conditionId, index) => {
            if (conditionId === item.conditionId) {
              this.echoList.splice(index, 1);
              this.formData.selectiveConditionList.splice(index, 1);
            }
          });
        });
      }
    },
    // 选择申报条件
    markInfoCheck () {
      this.dialogData.dialogState = true
      this.dialogGetData()
    },
    dialogSizeChange (pageSize) {
      this.dialogData.pageSize = pageSize;
      this.dialogGetData();
    },
    deleteSelectiveCondition (row) {
      this.echoList.forEach((conditionId, index) => {
        if (conditionId === row.conditionId) {
          this.echoList.splice(index, 1);
          this.formData.selectiveConditionList.splice(index, 1);
        }
      });
    },
    dialogGetData (pageNum = 1) {
      return this.$post('/biz/ct/condition/pageList', {
        pageNum: pageNum,
        pageSize: this.dialogData.pageSize,
        occupationName: this.dialogData.occupationName,
      }).then((res) => {
        this.dialogData.tableData = res.data.list
        this.dialogData.apiData = {
          total: res.data.total || 0,
          size: this.dialogData.pageSize || 10,
          current: this.dialogData.pageNum || 1,
        };
        this.tableKey = Date.now()
        this.$nextTick(() => {//这里用来做回显
          this.dialogData.tableData.forEach((i) => {
            if (this.echoList.includes(i.conditionId)) {
              // 如果有需要回显选中的数据就去做选中处理
              this.$refs.multipleTable.toggleRowSelection(i, true);
            }
          });
        });
      })
    },
    // 弹窗end
  },
}
</script>

<style lang="less" scoped>
.templateList {
  .el-form-item-val {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    padding-left: 20px;
    min-height: 250px;
    overflow-y: auto;
    .el-checkbox-group {
      display: flex;
      flex-direction: column;
    }
  }
  .customBtn:nth-child(2) {
    background-color: #5c6be8;
    border: none;
    &:hover {
      background-color: #6875ee;
    }
  }
  /deep/ .blue-background-class{
    background: rgba(92, 107, 232,.3);
  }
}
</style>